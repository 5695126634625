<template>
    <div class="Details">
        <!-- <Header/> -->
        <div class="warp">
            <div class="details_contant">
                <div class="title">
                    <h2>{{$t('lb_event_details_title2')}} / {{$t('lb_event_details_title')}}</h2>
                    <div class="main_title">
                        <h2>{{title}}</h2>
                    </div>
                </div>
                <!--                <div class="pic_img">-->
                <!--                    <img :src="imgURL" alt="">-->
                <!--                </div>-->
                <div class="active_details">
                    <div v-html="content"></div>
                </div>
            </div>
            <div class="footers">
                <div class="left">
                    <div class="Shareto">{{$t('Share.title')}}</div>
                    <ul>
<!--                        <li class="weixing">-->
<!--                            <img src="/images/Summit/icon_weixing@2x.png" alt="">-->
<!--                            <div class="QRcode">-->
<!--                                <img src="/images/Summit/img_erweima@2x.png" alt="">-->
<!--                            </div>-->
<!--                        </li>-->
                        <li>
                            <a :href="`http://service.weibo.com/share/share.php?url=${currentUrl()}&pic=${imgURL}&title=${title}`"><img
                                    src="/images/Summit/icon_xinlang@2x.png" alt=""></a></li>
<!--                        <li><img src="/images/Summit/icon_in@2x.png" alt=""></li>-->
                        <li><a :href="`https://www.facebook.com/sharer.php?u=${currentUrl()}`" target="_blank"><img
                                src="/images/Summit/icon_f@2x.png" alt=""></a></li>
                        <li>
                            <a :href="`https://twitter.com/intent/tweet?url=${currentUrl()}&via=conmartofficial&text=${title}`"
                               target="_blank"><img src="/images/Summit/icon_gezi@2x.png" alt=""></a></li>
                    </ul>
                </div>
                <div class="right">

                    <a class="Return" @click="Return">
                        {{$t('Share.back')}}
                    </a>
                </div>
            </div>
            <Footer/>
        </div>
    </div>
</template>
<script>
    import Header from '../../components/header'
    import Footer from '../home/children/Footer'
    import {getexpoActivityContent} from '@/publicApi/expoActivityContent'

    import {mkI18n} from "@/i18n"

    const module = "activityDetail";
    const storeModule = {
        namespaced: true,
        state: () => ({
            title: '',
            imgURL: '',
            content: ''
        }),
        actions: {
            load: async ({commit}, {id, lang}) => {
                let {data, msg, status} = await getexpoActivityContent({id, lang});
                if (status !== 0) {
                    return Promise.reject({code: 404})
                }
                commit('setData', data.contents[0])
                // console.log(data);
                return data
            }
        },
        mutations: {
            setData: (state, {title, cover, content}) => {
                state.title = title;
                state.imgURL = cover;
                state.content = content
            }
        }
    };

    const APILang = {'zh': 'chs', 'en': 'eng'};

    export default {
        data() {
            return {}
        },
        created() {
            const i18n = mkI18n({
                'lb_event_details_title2': ['会议·活动', "Activity"],
                'lb_event_details_title': ['活动详情', "Information"],
            });
            Object.keys(i18n).forEach(e => {
                this.$i18n.mergeLocaleMessage(e, i18n[e])
            })
        },
        asyncData: async function ({store, route, meta}) {
            store.registerModule(module, storeModule);

            const data = await store.dispatch(`${module}/load`, {
                id: route.params.id,
                lang: APILang[route.params.lang]
            });
            // console.log("a", data);
            if (meta) {
                meta.title = data.contents[0].title;
                meta.keywords = data.contents[0].seoKeywords || meta.keywords;
                meta.description = data.contents[0].seoDesciption || meta.description;
            }
        },
        components: {
            Header,
            Footer
        },
        mounted() {
            // this.getCurrentActivityContent()
        },
        beforeRouteUpdate(to, from, next) {
            this.$store.dispatch(`${module}/load`, {
                id: to.params.id,
                lang: APILang[to.params.lang],
            }).then(() => next()).catch(e => {
                console.log(`${module}/load error`, to.params.id, e);
            })
        },
        beforeDestroy() {
            try {
                this.$store.unregisterModule(module)
            } catch (e) {

            }
        },
        methods: {
            Return() {
                this.$router.go(-1);//返回上一层
            },
            currentUrl() {
                if (process.env.VUE_ENV !== 'server')
                    return encodeURIComponent(window.location.href)
                return ""
            }
        },
        computed: {
            title() {
                return this.$store.state[module].title
            },
            imgURL() {
                return this.$store.state[module].imgURL
            },
            content() {
                return this.$store.state[module].content
            },
        }
    }
</script>
<style lang="scss" scoped>
    .warp {
        width: 100%;
        background: #F4F4F4;

        .details_contant {
            margin: auto;
            padding: 20px 1em;
            background: #fff;
            min-height: 500px;
            @include design-width-setting;

            .title {
                h2 {
                    font-weight: 400;
                    color: rgba(51, 51, 51, 1);
                    font-size: 16px;
                    /*padding-bottom: 56px;*/
                    /*line-height: 70px;*/
                }

                .main_title {
                    width: 100%;
                    border-bottom: 1px solid rgba(216, 216, 216, 0.5);

                    h2 {
                        width: 100%;
                        text-align: center;
                        font-weight: 600;
                        color: rgba(51, 51, 51, 1);
                        font-size: 32px;
                        padding: 40px 0 25px;
                        line-height: 1;
                    }
                }
            }

            .pic_img {
                width: 70%;
                margin: 50px auto 65px;

                img {
                    width: 100%;
                }

                @include sm {
                    width: auto;
                    margin: 10px;
                }
            }

            .active_details {
                padding: 20px 1em;

                $font-sizes: 10px, 12px, 14px, 16px, 18px, 20px, 24px, 36px, 48px, 60px, 72px;

                @each $size in $font-sizes {
                    .ql-size-#{$size} {
                        font-size: $size;
                    }
                }

                h2 {
                    font-weight: 600;
                    color: rgba(51, 51, 51, 1);
                    font-size: 18px;
                    line-height: 34px;
                }

                p {
                    font-weight: 400;
                    color: rgba(51, 51, 51, 1);
                    font-size: 15px;
                    line-height: 30px;
                }

                > > > img {
                    max-width: 100%;
                    display: block;
                    margin: 2px auto;
                }
            }

            .EssentialInformation {
                padding-bottom: 22px;

                h2 {
                    font-weight: 600;
                    color: rgba(51, 51, 51, 1);
                    font-size: 18px;
                    line-height: 34px;
                }

                p {
                    font-weight: 400;
                    color: rgba(51, 51, 51, 1);
                    font-size: 15px;
                    line-height: 30px;
                }

            }

            .Organization {
                padding-bottom: 22px;

                h2 {
                    font-weight: 600;
                    color: rgba(51, 51, 51, 1);
                    font-size: 18px;
                    line-height: 34px;
                }

                p {
                    font-weight: 400;
                    color: rgba(51, 51, 51, 1);
                    font-size: 15px;
                    line-height: 30px;
                }
            }

            .theme {
                padding-bottom: 22px;

                h1 {
                    font-weight: 600;
                    color: rgba(51, 51, 51, 1);
                    font-size: 18px;
                    line-height: 34px;
                }

                p {
                    font-weight: 400;
                    color: rgba(51, 51, 51, 1);
                    font-size: 15px;
                    line-height: 30px;
                }
            }

            .form {
                padding-bottom: 22px;

                h1 {
                    font-weight: 600;
                    color: rgba(51, 51, 51, 1);
                    font-size: 18px;
                    line-height: 34px;
                }

                p {
                    font-weight: 400;
                    color: rgba(51, 51, 51, 1);
                    font-size: 15px;
                    line-height: 30px;
                }
            }

            .Activity {
                // padding-bottom: 22px;
                h1 {
                    font-weight: 600;
                    color: rgba(51, 51, 51, 1);
                    font-size: 18px;
                    line-height: 34px;
                }

                p {
                    font-weight: 400;
                    color: rgba(51, 51, 51, 1);
                    font-size: 15px;
                    line-height: 30px;
                    margin-bottom: 32px;
                }

                .Activity_img {
                    width: 100%;
                    height: 100%;

                    img {
                        width: 100%;
                        height: 100%;
                    }
                }

                .Distinguished {
                    margin-top: 30px;

                    p {
                        font-weight: 600;
                        color: rgba(51, 51, 51, 1);
                        font-size: 18px;
                        // line-height: 34px;
                    }
                }
            }

            .organization {
                padding-bottom: 22px;

                h1 {
                    font-weight: 600;
                    color: rgba(51, 51, 51, 1);
                    font-size: 18px;
                    line-height: 34px;
                }

                p {
                    font-weight: 400;
                    color: rgba(51, 51, 51, 1);
                    font-size: 15px;
                    line-height: 30px;
                }
            }
        }
    }

    .footers {
        height: 34px;
        border-top: 1px solid #D8D8D8;
        padding-top: 40px;
        margin: auto;
        padding-bottom: 148px;
        @include design-width-setting;
        overflow: hidden;

        .left {
            float: left;

            .Shareto {
                height: 36px;
                font-weight: 400;
                color: rgba(51, 51, 51, 1);
                font-size: 18px;
                line-height: 36px;
                float: left;
            }

            ul {
                margin-left: 15px;
                overflow: hidden;

                li {
                    margin-right: 18px;
                    float: left;
                }

                @include sm {
                }
            }
        }

        .right {
            float: right;

            .immediately {
                display: block;
                width: 126px;
                height: 40px;
                line-height: 40px;
                text-align: center;
                color: #ffffff;
                border-radius: 10px;
                background: #FAD214;
                font-weight: 400;
                color: rgba(255, 255, 255, 1);
                margin-right: 36px;
            }

            .Return {
                display: block;
                width: 108px;
                height: 40px;
                border: 1px solid #CCCCCC;
                border-radius: 10px;
                line-height: 40px;
                text-align: center;
                font-weight: 400;
                color: rgba(153, 153, 153, 1);
                cursor: pointer;
            }
        }
    }

    .weixing {
        position: relative;

        .QRcode {
            position: absolute;
            left: -37px;
            top: 58px;
            display: none;
        }
    }

    .weixing:hover .QRcode {
        display: block;
    }
</style>



                        
                        
                        