import { render, staticRenderFns } from "./Activitydetails.vue?vue&type=template&id=0ec41f8d&scoped=true&"
import script from "./Activitydetails.vue?vue&type=script&lang=js&"
export * from "./Activitydetails.vue?vue&type=script&lang=js&"
import style0 from "./Activitydetails.vue?vue&type=style&index=0&id=0ec41f8d&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0ec41f8d",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/data/wwwroot/conmart-expo-website/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('0ec41f8d')) {
      api.createRecord('0ec41f8d', component.options)
    } else {
      api.reload('0ec41f8d', component.options)
    }
    module.hot.accept("./Activitydetails.vue?vue&type=template&id=0ec41f8d&scoped=true&", function () {
      api.rerender('0ec41f8d', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/ActivityDetails/Activitydetails.vue"
export default component.exports